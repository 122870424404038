import { Component, ContentChild, OnInit, signal, TemplateRef } from '@angular/core';
import { FdlModalComponent } from '@fyle/modal';
import { FdlModalActionsComponent } from '@fyle/modal-actions';
import { FdlModalContentComponent } from '@fyle/modal-content';
import { FdlModalHeaderComponent } from '@fyle/modal-header';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationModalConfig, ModalSize, ModalHeaderTypes } from '@fyle/model-shared';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-confirmation-modal',
  standalone: true,
  imports: [
    CommonModule,
    FdlModalComponent,
    FdlModalActionsComponent,
    FdlModalContentComponent,
    FdlModalHeaderComponent,
  ],
  templateUrl: './ui-confirmation-modal.component.html',
  styleUrls: ['./ui-confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
  modalHeaderType = ModalHeaderTypes;

  modalConfiguration: ConfirmationModalConfig;

  modalSize = ModalSize;

  noMinHeight = signal<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customTemplate: TemplateRef<any>;

  defaultConfirmationMessage = 'Do you want to continue?';

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {}

  setCustomTemplate(): void {
    if (this.modalConfiguration.content instanceof TemplateRef) {
      this.customTemplate = this.modalConfiguration.content;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.modalConfiguration = this.dialogConfig.data;
    if (this.modalConfiguration.noMinHeight !== undefined) {
      this.noMinHeight.set(this.modalConfiguration.noMinHeight);
    }
    this.setCustomTemplate();
  }
}
