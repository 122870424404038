export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: '62198175306-h477rqb0lmglnlolib9pn2m9pjudrm2h.apps.googleusercontent.com',
    ROOT_URL: 'https://staging.fyle.tech',
    APP_URL: 'https://staging1.fyle.tech/app',
    APP_ROUTER_URL: 'https://staging-accounts.fyle.tech',
    ROUTER_URL: 'https://staging-accounts.fyle.tech',
    SENTRY_DSN: 'https://971bdf3cefe24ef88d6e1cbb65b5d535@sentry.io/3554166',
    SEND_ERROR_TO_SENTRY: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.1,
    ENVIRONMENT: 'staging1',
    RELEASE: '4a420a3290015931fafba57ca210029eb288ec3f',
    TARGET: 'fyle',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCnRehxBEzK3HYLvflpf2tCnT75Fqq7q9s',
    DWOLLA_ENVIRONMENT: 'sandbox',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe54',
    NETLIFY_FYLE_APP_KEY: 'b148528c-3116-44eb-bd04-3bb6e9bcb9ce',
    NETLIFY_FUNCTION_URL: 'https://fyle-app-staging1.netlify.app/.netlify/functions',
    DEPLOY_LOGS_WEBHOOK_URL: 'https://hooks.slack.com/services/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',
    C1_DEPLOY_LOGS_WEBHOOK_URL: 'https://hooks.slack.com/services/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',
    SLACK_COPILOT_FEEDBACK_WEBHOOK: 'https://hooks.slack.com/services/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',
    NETLIFY_ACCESS_TOKEN: 'lGbKhYoWjUZqXtRsietrw4vIS27AMCI5C6umcrxZQbQ',
    MIXPANEL_PROJECT_TOKEN: 'dce787f3a74f5ede8264af230a4b4652',
    MIXPANEL_PROXY_URL: 'https://staging.fyle.tech/mixpanel',
    USE_MIXPANEL_PROXY: 'true',
    ENABLE_MIXPANEL: 'true',
    YODLEE_FAST_LINK_URL: 'https://fl4.preprod.yodlee.com/authenticate/development-448/fastlink/?channelAppName=tieredpreprod',
  };